<template>
  <v-container
    class="pa-0"
    fluid
    >
    <div
      v-if="!selected"
      class="full-background"
      ></div>

    <div>
      <v-card
        flat
        color="transparent"
        class="text-h3 font-weight-bold d-flex align-center justify-center white--text"
        height="300"
        v-if="!selected"
        >
        Dashboards
      </v-card>

      <v-card
        flat
        tile
        :class="selected ? 'transparent' : 'lower-card-opacity'"
        >
        <v-card-text>
          <div
            class="text-h4 px-3 d-flex flex-wrap justify-end white--text"
            style="gap: 8px"
            >
            <create
              v-if="!selected"
              ></create>
            <edit
              class="mr-1"
              v-if="selected"
              :dashboard="selected"
              ></edit>
            <delete
              v-if="selected"
              :dashboard="selected"
              ></delete>
          </div>
          <template>
            <div
              v-if="!selected"
              >
              <v-row>
                <template
                  v-for="card in cards"
                  v-if="card.allowedRoles.includes(currentUser.currentRoleName)"
                  >
                  <v-col
                    cols="auto"
                    class="pa-3"
                    >
                    <v-card
                      tile
                      class="elevation-3"
                      height="200"
                      width="200"
                      @click="selected = card"
                      >
                      <v-card-text
                        class="fill-height d-flex flex-row align-center justify-center"
                        >
                        <div>
                          {{ card.name }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </div>
            <template
              v-else
              >
              <div
                class="d-flex flex-row justify-space-between"
                >
                <v-btn
                  small
                  text
                  @click="selected = null"
                  >
                  <v-icon
                    color="blue"
                    >
                    mdi-chevron-left
                  </v-icon>
                  volver
                </v-btn>
              </div>
              <div
                class="d-flex justify-center"
                >
                <iframe 
                  class="pt-3"
                  :width="$vuetify.breakpoint.width > 1368 ? '1368' : ($vuetify.breakpoint.width - 1368)" 
                  height="800" 
                  :src="selected.url" 
                  frameborder="0" 
                  allowFullScreen="true"></iframe>
              </div>
            </template>
          </template>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { Dashboards } from '@/graphql/queries/dashboards'

const Create = () => import('@/components/dashboards/Create')
const Delete = () => import('@/components/dashboards/Delete')
const Edit = () => import('@/components/dashboards/Edit')

export default {
  data () {
    return {
      selected: null,
      cards: []
    }
  },

  created () {
    this.$apollo.query({
      query: Dashboards,
      variables: {
        projectId: this.$route.params.id
      }
    }).then ( res => {
      this.cards = res.data.dashboards
      // this.selected = this.cards[0]
    })
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  components: { Create, Delete, Edit }
}
</script>
