import gql from 'graphql-tag'

export const Dashboard = gql`
  query dashboard($id: ID) {
    dashboard(id: $id) {
      id
      name
      url
    }
  }
`

export const Dashboards = gql`
  query dashboards($projectId: ID!) {
    dashboards(projectId: $projectId) {
      id
      name
      allowedRoles
      url
    }
  }
`
